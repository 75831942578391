<template>
  <div
    v-if="item.price"
    class="shop-product-card__price"
    :class="{ 'has-discount': item.discount?.price }"
  >
    <span
      v-if="item.discount?.price"
      class="shop-product-card__price-discounted"
    >
      CHF {{ parseFloat(item.discount?.price).toFixed(2) }}

    </span>

    <span
      class="shop-product-card__price-original"
    >
      CHF {{ parseFloat(item.price).toFixed(2) }}&nbsp;
      <span v-if="isHero">{{ useTranslation('general', 'inclusive_mwst') }}</span>
    </span>
  </div>
</template>

<script setup>
const props = defineProps({
    isHero: {
        type: Boolean,
        default: false,
    },

    product: {
        type: Object,
        default: null,
    },
});

const item = inject('item', props.product);
</script>

<style lang="scss" scoped>
.shop-product-card__price {
    @include typo-size('p');
    @include typo-font('light');

    .shop-cart-popup & {
        margin-bottom: 5px;
    }

    .shop-cart-list-entry & {
        display: flex;
        width: 100%;
    }
}
.shop-product-card__price-discounted, .shop-product-card__price-original {
    @include typo-size('p');
    @include typo-font('light');
}
.shop-product-card__price-discounted {
    display: flex;
    margin-right: 11px;

    p {
        margin-left: 10px;
    }
}
.shop-product-card__price-original {
    display: flex;
    .has-discount & {
        text-decoration: line-through;
    }
}
</style>
